/** @jsx jsx */

import { jsx } from "theme-ui"
import { Connection, ContentNode, PageRoot } from "../data/content"
import { IndexPageContainer } from "../components/indexes"
import React, { Fragment } from "react"
import { useTranslation, Trans } from "react-i18next"
import SEO from "../components/seo"
import { PillarLogo } from "../images/pillars"
import { useTextDirection } from "../i18n"
import { graphql } from "gatsby"
import { JobsListingBlock } from "../components/featured"

const JobsIndexPage: PageRoot<{ jobs: Connection<ContentNode> }> = ({
  data: { jobs },
  location: { pathname },
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation()
  const dir = useTextDirection(language)

  return (
    <IndexPageContainer
      title={t(`Jobs`)}
      bg="accentTint"
      color="accent"
      isRtl={dir.isRtl}
    >
      <SEO path={pathname} title={"Jobs"} description={"Test"} />
      <JobsListingBlock edges={jobs.edges} />
    </IndexPageContainer>
  )
}

export const pageQuery = graphql`
  query JobPage {
    jobs: allMarkdownRemark(
      filter: { fields: { type: { eq: "jobs" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
  }
`

export default JobsIndexPage
